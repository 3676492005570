<template>
  <loai-trang-thai />
</template>
<script>
import LoaiTrangThai from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/LoaiTrangThai.vue'

export default {
  components: {
    LoaiTrangThai,
  },
}
</script>
