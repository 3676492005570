<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @add-item="addCommom"
      @delete-item="$refs.confirmDelete.showModal()"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="danhSachLoaiTrangThai"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="danhSachLoaiTrangThai"
      :total="tongSoLoaiTrangThai"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @selected-item="onSelectRow"
      @column-filter="filterDanhSachLoaiTrangThai"
      @update-item="onUpdate"
      @delete-item="setItemUpdate"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="filter.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      @reset-form="resetForm"
      @handle-ok="onSave"
      @handle-focus="handleFocusError"
    >
      <b-form>
        <b-form-group
          class="required"
          label="Mã loại trạng thái"
          label-for="maLoaiTrangThai"
        >
          <validation-provider
            v-slot="{ errors }"
            key="maLoaiTrangThai"
            :rules="{ required: true }"
            name="maLoaiTrangThai"
          >
            <b-form-input
              id="maLoaiTrangThai"
              ref="maLoaiTrangThai"
              v-model="form.maLoaiTrangThai"
              type="text"
              :autofocus="form.id ? false : true"
              :class="{required: errors.length > 0}"
              placeholder="Nhập mã loại trạng thái"
            />
            <span class="text-danger">{{ errors[0] }}</span>
            <span class="text-danger">{{ errorMessage }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          class="required"
          label="Tên loại trạng thái"
          label-for="tenLoaiTranThai"
        >
          <validation-provider
            v-slot="{ errors }"
            key="tenLoaiTảngThai"
            :rules="{ required: true }"
            name="tenLoaiTrangThai"
          >
            <b-form-input
              id="tenLoaiTranThai"
              ref="tenLoaiTrangThai"
              v-model="form.tenLoaiTrangThai"
              type="text"
              :class="{required: errors.length > 0}"
              placeholder="Nhập tên loại trạng thái"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Trạng thái"
        >
          <b-form-checkbox
            v-model="form.status"
            switch
          />
        </b-form-group>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete()"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BForm,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import END_POINTS from '@/api/endpoints'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'
import { compareObjects } from '@/utils'
import { checDeleteMultiple } from '../../../../utils/common-utils'

export default {
  components: {
    ActionBtn,
    GoodTable,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BForm,
    CommonModal,
    ValidationProvider,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      selectedNumber: 0,
      isShow: false,
      isShowFooter: false,
      optionsTrangThai: STATUS,
      title: 'Thêm mới thông tin loại trạng thái',
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã loại trạng thái',
          field: 'maLoaiTrangThai',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Loại trạng thái',
          field: 'tenLoaiTrangThai',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          tdClass: 'text-left',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      filter: {
        maLoaiTrangThai: '',
        tenLoaiTrangThai: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maLoaiTrangThai: '',
        tenLoaiTrangThai: '',
        status: true,
      },
      selectedRows: [],
      tongSoLoaiTrangThai: 0,
      errorMessage: '',
      beginObject: {},
    }
  },
  computed: {
    ...mapGetters({
      danhSachLoaiTrangThai: 'danhMuc/getDanhSachLoaiTrangThai',
    }),
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.LOAI_TRANG_THAI.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.LOAI_TRANG_THAI.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.LOAI_TRANG_THAI.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.$store.dispatch('danhMuc/LayDanhSachLoaiTrangThai', this.filter).then(res => {
      this.tongSoLoaiTrangThai = res.data.totalCount || 0
    })
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.form)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    pageChange() {
      const data = this.$refs.danhSachLoaiTrangThai.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$store.dispatch('danhMuc/LayDanhSachLoaiTrangThai', {
        ...payload,
      }).then(res => {
        this.tongSoLoaiTrangThai = res.data.totalCount || 0
      })
    },
    filterDanhSachLoaiTrangThai(param = {}) {
      this.filter = {
        ...param,
        pageNumber: 1,
        pageSize: this.filter.pageSize,
        maLoaiTrangThai: param.maLoaiTrangThai ? param.maLoaiTrangThai.replace(/\s+/g, ' ').trim() : '',
        status: this.filter.status,
        tenLoaiTrangThai: param.tenLoaiTrangThai ? param.tenLoaiTrangThai.replace(/\s+/g, ' ').trim() : '',
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.danhSachLoaiTrangThai) {
        payload = {
          ...payload,
          ...this.$refs.danhSachLoaiTrangThai.getCurrentPage(),
        }
      }
      this.payload = {
        ...param,
        pageNumber: 1,
        pageSize: this.filter.pageSize,
      }

      this.$store.dispatch('danhMuc/LayDanhSachLoaiTrangThai', {
        ...payload,
        pageNumber: 1,
        pageSize: this.filter.pageSize,
      }).then(res => {
        this.tongSoLoaiTrangThai = res.data.totalCount || 0
      })
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.$store.dispatch('danhMuc/LayDanhSachLoaiTrangThai', this.filter).then(res => {
          this.tongSoLoaiTrangThai = res.data.totalCount || 0
        })
      })
    },
    refreshPage() {
      this.filter = {
        maLoaiTrangThai: '',
        tenLoaiTrangThai: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.danhSachLoaiTrangThai.$refs.vbTables.reset()
      this.$store.dispatch('danhMuc/LayDanhSachLoaiTrangThai', this.filter).then(res => {
        this.tongSoLoaiTrangThai = res.data.totalCount || 0
      })
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới thông tin loại trạng thái'
      this.isCreate = true
      this.$refs.commonModal.showModal()
    },
    onSelectRow(rows) {
      this.selectedRows = rows
      this.selectedNumber = rows.length
    },
    onSave() {
      if (this.isCreate) {
        this.$axios.post(END_POINTS.LOAI_TRANG_THAI.THEM_MOI, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterDanhSachLoaiTrangThai()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      } else {
        this.$axios.put(END_POINTS.LOAI_TRANG_THAI.SUA, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterDanhSachLoaiTrangThai()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      }
    },
    onUpdate(item) {
      this.isShow = true
      this.form = {
        id: item.id,
        maLoaiTrangThai: item.maLoaiTrangThai,
        tenLoaiTrangThai: item.tenLoaiTrangThai,
        status: item.status,
      }
      this.title = 'Cập nhật thông tin loại trạng thái'
      this.isCreate = false
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.form)
        this.$refs.commonModal.showModal()
      })
    },
    onDelete() {
      this.isShow = false
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.LOAI_TRANG_THAI.XOA, payload, false).then(res => {
        if (res.data?.succeeded) {
          if (this.danhSachLoaiTrangThai.length === 1) {
            const currentPage = this.$refs.danhSachLoaiTrangThai.getCurrentPage()
            const index = currentPage.pageNumber - 1
            this.$refs.danhSachLoaiTrangThai.resetCurrentPage(index === 0 ? 1 : index)
          }
          this.filterDanhSachLoaiTrangThai()
          checDeleteMultiple(this, res.data)
        }
      })
    },
    resetForm() {
      this.form = {
        maLoaiTrangThai: '',
        tenLoaiTrangThai: '',
        status: true,
      }
      this.errorMessage = ''
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['thuTucHanhChinh'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
